*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    font-family: 'Roboto', sans-serif;
}

.aboutParagraph::-webkit-scrollbar{
    width: 12px;
    background-color: rgb(43, 54, 47);
}

.aboutParagraph::-webkit-scrollbar-thumb{
    background-color: lightcoral;
    border-radius: 20px;
    border: 3px solid rgb(43, 54, 47);
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
    background-color: rgb(255, 255, 255);
}

.websiteMenu
{
    position: absolute;
    right: 0;
    width: 20%;
    height: 100%;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    opacity: 0;
    transition: opacity 1s;
}

.websiteMenu.show{
    opacity: 1;
}

.websiteTitle
{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    font-size: 80px;
    font-family: 'Roboto', sans-serif;
    color: rgb(87, 117, 87);
    display: flex;
    opacity: 1;
    transition: 0.5s;
    font-style: italic;
    text-decoration: underline lightcoral;
}

.websiteTitle.hide{
    opacity: 0;
}

.menuTitle{
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    background: rgb(57, 83, 57);
    color: lightcoral;
    transition: 0.2s;
    text-align: left;
    font-style: italic;
    font-weight: 20px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
}

.loadingScreen{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(57, 83, 57);
    visibility: visible;
    opacity: 1;
    transition: 1s;
}

.loadingScreen p{
    position: absolute;
    left: 50%;
    top: 50%;
    color: lightcoral;
    transform: translate(-50%, -50%);
    font-family: 'Roboto', sans-serif;
}

.loadingScreen.ended{
    opacity: 0;
}

.loadingScreen.hide{
    visibility: hidden;
}

.loadingInfo{
    position: absolute;
    transform: top 60%;
}

.aboutParagraph{
    background-color: rgb(56, 75, 56);
    transform: translate(-100%);
    padding: 10px;
    font-family: sans-serif;
    opacity: 0;
    overflow-y: scroll;
    height: 55%;
    width: 150%;
    overflow-x: hidden;
    visibility: hidden;
    color: white;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    line-height: 25px;
}

.aboutParagraph.show{
    opacity: 1;
    visibility: visible;
}

.aboutParagraph a{
    color: white;
}

.enter{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: white;
    text-decoration: none;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    transition: 0.5s;
    font-size: 20px;
    padding: 5px;
    opacity: 0;
    font-family: 'Roboto', sans-serif;
}

.enter.show{
    opacity: 1;
}

.enter:hover{
    color: lightcoral;
    font-size: 25px;
}

.enter.hide{
    opacity: 0;
}



.info{
    position: absolute;
    left: 50%;
    top: 95%;
    opacity: 0;
    color: white;
    transition: 1s;
    transform: translate( -50%);
    font-family: 'Roboto', sans-serif;
}

.info.show{
    opacity: 1;
}

.currentLocation
{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
}

.navigation
{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
}

.accordianButton1{
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    background: rgb(57, 83, 57);
    color: white;
    transition: 0.2s;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.accordianButton1:hover{
    color: rgb(96, 126, 96);
}

.accordianButton1::after{
    content: '\25be';
    float: right;
    transform: scale(1.5);
}

.accordianButton1--active{
    background: black;
}

.accordianButton1--active::after{
    content: '\25b4';
}

.accordianButton2{
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    background: rgb(57, 83, 57);
    color: white;
    transition: 0.2s;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.accordianButton2:hover{
    color: rgb(96, 126, 96);
}

.accordianButton2::after{
    content: '\25be';
    float: right;
    transform: scale(1.5);
}

.accordianButton2--active{
    background: black;
}

.accordianButton2--active::after{
    content: '\25b4';
}

.recordingsList{
    list-style: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s;
    font-family: 'Roboto', sans-serif;
    background: rgb(96, 126, 96);
}

.recordingsList li a{
    text-decoration: none;
    color: white;
    padding-left: 10px;
    font-size: 12px;
    height: 12px;
    font-family: 'Roboto', sans-serif;
}

.recordingsList li a:hover{
    color: black;
}

.exit{
    text-align: left;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    background: rgb(57, 83, 57);
    color: white;
    transition: 0.5s;
    opacity: 0;
    font-family: 'Roboto', sans-serif;
}

.exit:hover{
    color: rgb(96, 126, 96);
}

.exit.show{
    opacity: 1;
}

.about{
    text-align: left;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    background: rgb(57, 83, 57);
    color: white;
    transition: 0.2s;
    font-family: 'Roboto', sans-serif;
}

.about.show{
    background-color: black;
}

.about:hover{
    color: rgb(96, 126, 96);
}

.controls{
    position: absolute;
    width: 100%;
    height: 10%;
    background-color: rgb(57, 83, 57);
    bottom: 0;
    text-align: center;
    margin: auto;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
}

.controls.show{
    opacity: 1;
    visibility: visible;
}

.controls button{
    width: 50px;
    height: 80px;
    box-sizing: border-box;
    overflow: none;
    color: lightcoral;
    background: none;
    border: none;
    transition: 0.2s;
}


.controls button:hover{
    color: black;
}